<template>
  <div class="free">
    <div class="free-title">
      <div class="switch-style">
        <span class="label">识别方式: </span>
        <span class="style">帐号密码</span>

        <span class="label">重置周期:</span>
        <span class="style">30天</span>

        <span class="edit">
          <a-button type="link" icon="edit">修改</a-button>
        </span>
      </div>
      <span class="tip">最近重置时间:2020-10-21</span>
      <a-button type="primary">新增人员</a-button>
    </div>
    <div class="free-remark">
      <img class="icon" src="../../../../assets/img/saleman/icon.png">
      <div class="tip">
        <p>1、支持帐号密码、微信号等方式做免单人员识别;点击修改可以切换不同模式。</p>
        <p>2、同一人员支持绑定多个套餐；每个周期内人员杯量自动重置。</p>

      </div>
    </div>

    <a-table
      class="free-list"
      :columns="columns"
      :row-key="record => record.uuid"
      :data-source="users"
      :pagination="pagination"
      :loading="loading"

      :scroll="{ x: '100%'}"
    >
      <div slot="name" slot-scope="device">
        <p> {{ device.deviceUuid }}</p>
        <p> {{ device.deviceName }}</p>
      </div>
    </a-table>
  </div>
</template>

<script>
  export default {
    name: 'free',
    components: {},
    mounted: function () {
    },
    data: function () {
      return {
        users: [],
        loading: false,
        columns: [
          {
            title: '识别编号',
            fixed: 'left',
            scopedSlots: {customRender: 'name'},
            width: 160,
          },
          {
            title: '人员信息',
            width: 120,
            dataIndex: "area"
          }, {
            title: '可用套餐',
            width: 136,
            dataIndex: "deviceName",

          },
          {
            title: '剩余可用',
            width: 96,
            scopedSlots: {customRender: 'onlineStatus'},
          },
          {
            title: '免费杯量',
            width: 96,
            scopedSlots: {customRender: 'department'},
          },
          {
            title: '录入时间',
            width: 120,
            scopedSlots: {customRender: 'useLevel'},
          },
          {
            title: '操作',
            width: 80,
            scopedSlots: {customRender: 'more'},
          },
        ],
        pagination: {
          start: 0,
          curPage: 1,
          limit: 10,
          total: 1,
        },
      }
    },
    methods: {},
  }
</script>

<style lang="scss" scoped>
  .free {
    padding: 0 $small-space;

    &-title {
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      .tip{
        @include font-little();
        display: inline-block;
        margin-right: $middle-space;
      }
      .switch-style {
        flex: 1;



        .label {
          @include font-little();
          display: inline-block;
          margin-left: $small-space;
        }

        .style {
          font-weight: 500;
          color: $color-black;
        }

        .edit {
          display: inline-block;
          position: relative;
        }

        .edit:after {
          content: '';
          display: block;
          position: absolute;
          box-sizing: border-box;
          width: 22px;
          height: 22px;
          left: 24px;
          top: 30px;
          transform: rotateZ(45deg);
          border-top: 1px solid #e1e4e8;
          border-left: 1px solid #e1e4e8;
          background-color: #f6f6f6;
          z-index: 2;
        }
      }
    }

    &-remark {
      display: flex;
      background: rgb(246, 246, 246);
      border: 1px solid rgb(225, 228, 232);
      padding: $middle-space;
      position: relative;
      margin-top: $small-space;
      width: 100%;

      .icon {
        width: 36px;
      }
      .tip{
        margin: 0px;
        padding: 0px $middle-space;
        @include font-little-h();
        color: rgb(107, 121, 142);
      }
    }

    &-list{
      margin:  $middle-space 0;
    }

    &-list  .ant-table {
      min-height: 200px !important;
    }

    &-list  .ant-empty-normal {
      margin: 100px 0 !important;
    }
  }
</style>
